import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1279.000000 1280.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">
          <path d="M5413 7210 c-13 -5 -23 -11 -23 -14 0 -7 40 6 46 15 6 10 6 10 -23
-1z"/>
       <path d="M1920 3763 c-14 -2 -56 -11 -94 -20 -61 -14 -70 -19 -82 -48 -10 -26
-13 -128 -14 -483 0 -268 4 -460 9 -475 17 -44 43 -51 135 -36 184 30 397 6
539 -61 76 -36 93 -37 152 -8 l45 22 0 67 c0 58 -3 67 -20 72 -11 3 -20 13
-20 22 0 9 9 19 20 22 11 3 20 13 20 23 0 10 7 25 15 34 23 22 18 28 -15 21
-23 -5 -32 -3 -37 11 -4 11 -1 21 9 28 13 8 7 16 -34 48 -49 39 -108 119 -108
146 0 9 -7 11 -20 7 -11 -3 -50 5 -87 18 -104 38 -208 51 -331 43 -59 -4 -120
-8 -137 -9 -21 -1 -30 3 -33 15 -6 33 43 42 218 42 172 0 166 1 353 -61 14 -4
17 1 17 28 0 37 5 35 -140 81 -56 18 -89 21 -230 21 -91 -1 -176 -3 -190 -4
-17 -3 -25 1 -28 13 -6 33 43 42 218 42 172 0 169 0 352 -60 13 -5 19 1 24 22
12 51 51 118 91 157 89 90 250 132 473 124 160 -6 182 -11 178 -41 -2 -15 -9
-20 -23 -18 -11 2 -74 5 -140 8 l-120 6 45 -28 c25 -15 47 -31 50 -34 3 -4 37
-10 75 -14 98 -10 115 -15 115 -36 0 -28 -12 -31 -71 -20 -29 6 -55 8 -58 5
-2 -3 5 -25 17 -50 11 -24 24 -66 28 -94 6 -49 7 -50 46 -56 33 -6 39 -10 36
-28 -3 -19 -8 -22 -45 -19 -31 2 -43 -1 -43 -10 0 -7 -3 -23 -6 -35 -6 -20 -2
-23 22 -23 48 0 74 -11 74 -31 0 -26 -9 -28 -69 -17 -53 9 -54 9 -81 -24 l-28
-33 52 -6 c104 -12 126 -19 126 -39 0 -19 -6 -20 -117 -14 -133 7 -193 -8
-193 -46 0 -19 7 -20 92 -20 50 0 120 -5 155 -11 57 -10 64 -14 61 -33 -2 -16
-9 -20 -28 -18 -14 1 -76 5 -138 9 l-113 6 3 -74 3 -74 90 -3 c50 -1 112 -7
138 -12 72 -15 102 -12 123 11 18 20 19 45 22 490 3 555 10 518 -100 544 -209
50 -501 13 -631 -78 l-29 -20 -88 42 c-113 54 -241 81 -375 80 -55 -1 -111 -3
-125 -6z m280 -144 c97 -15 224 -61 228 -82 5 -27 -25 -31 -76 -9 -89 39 -204
55 -341 49 -69 -3 -136 -9 -148 -12 -25 -8 -44 18 -26 36 26 26 244 38 363 18z
m-1 -120 c87 -14 222 -59 228 -76 13 -32 -15 -35 -89 -9 -108 37 -205 49 -338
43 -63 -3 -125 -9 -137 -12 -25 -8 -44 18 -26 36 26 26 247 37 362 18z m1
-360 c97 -15 224 -61 228 -82 5 -27 -25 -31 -76 -9 -86 37 -214 55 -347 49
-66 -3 -130 -9 -142 -13 -25 -7 -43 20 -26 37 26 26 247 37 363 18z m-1 -120
c87 -14 222 -59 228 -76 13 -32 -15 -35 -93 -9 -42 15 -109 31 -148 37 -70 11
-279 7 -323 -6 -25 -8 -44 18 -26 36 26 26 247 37 362 18z m36 -128 c121 -25
190 -53 190 -77 0 -24 -30 -23 -106 4 -92 33 -198 46 -322 39 -62 -4 -122 -9
-134 -13 -25 -7 -43 20 -26 37 31 31 268 37 398 10z"/>
<path d="M2690 3549 c-143 -40 -222 -144 -221 -290 0 -111 85 -231 182 -261
39 -11 39 -11 39 -63 0 -35 -5 -57 -15 -65 -12 -10 -15 -43 -15 -179 0 -117 4
-171 12 -179 7 -7 41 -12 85 -12 61 0 74 3 83 19 6 11 10 87 10 175 0 144 -2
158 -20 173 -15 13 -20 30 -20 68 l0 50 49 18 c68 24 133 89 160 161 38 103
20 210 -49 289 -69 78 -193 121 -280 96z m164 -64 c91 -43 141 -132 134 -239
-5 -77 -37 -132 -105 -180 -72 -50 -185 -49 -263 3 -87 58 -125 158 -100 262
16 67 87 141 156 163 57 18 128 14 178 -9z m-88 -558 c1 -34 -2 -47 -12 -47
-10 0 -14 14 -14 51 0 66 23 62 26 -4z m34 -232 l0 -145 -50 0 -50 0 0 145 0
145 50 0 50 0 0 -145z"/>
<path d="M2749 3440 c-19 -5 -61 -18 -92 -30 -48 -18 -59 -27 -78 -66 -16 -34
-20 -57 -16 -90 2 -25 7 -47 10 -51 3 -3 41 6 84 20 90 30 178 47 240 47 45 0
53 12 37 54 -12 30 -169 11 -281 -35 -56 -23 -87 -20 -81 8 4 24 106 61 221
80 53 9 97 19 97 23 0 9 -76 50 -91 49 -8 -1 -30 -5 -50 -9z"/>
<path d="M2780 3205 c-78 -16 -162 -46 -166 -60 -2 -6 7 -18 21 -27 19 -15 29
-16 52 -7 15 6 73 19 127 28 97 16 100 17 113 49 l13 32 -47 -1 c-27 0 -77 -7
-113 -14z"/>
<path d="M1620 2035 l0 -165 30 0 29 0 3 102 3 101 42 -99 c39 -92 44 -99 72
-102 l31 -3 0 165 0 166 -30 0 -30 0 0 -102 c0 -59 -4 -98 -9 -92 -5 5 -26 51
-47 102 -36 85 -41 92 -66 92 l-28 0 0 -165z"/>
<path d="M1900 2175 c0 -21 5 -25 30 -25 l30 0 0 -115 0 -115 -30 0 c-25 0
-30 -4 -30 -25 0 -25 1 -25 90 -25 89 0 90 0 90 25 0 21 -5 25 -30 25 l-30 0
0 115 0 115 30 0 c25 0 30 4 30 25 0 25 -1 25 -90 25 -89 0 -90 0 -90 -25z"/>
<path d="M2212 2183 c-18 -9 -40 -30 -49 -47 -22 -43 -22 -160 1 -204 21 -41
75 -66 121 -58 38 7 85 50 85 77 0 29 -44 24 -64 -6 -19 -28 -56 -33 -79 -10
-33 32 -33 168 -1 200 26 25 63 19 82 -15 18 -32 62 -43 62 -15 0 32 -30 73
-61 84 -42 15 -58 14 -97 -6z"/>
<path d="M2430 2035 l0 -165 105 0 105 0 0 25 c0 24 -2 25 -74 25 l-74 0 -4
50 -3 50 68 0 c65 0 67 1 67 25 0 24 -2 25 -67 25 l-68 0 1 37 1 38 77 3 c73
3 76 4 76 27 0 25 0 25 -105 25 l-105 0 0 -165z"/>
<path d="M2793 2183 c-30 -15 -53 -49 -53 -78 0 -26 46 -67 100 -89 61 -25 81
-47 64 -73 -21 -34 -98 -28 -109 7 -4 14 -15 20 -35 20 -28 0 -29 -1 -19 -29
15 -43 55 -65 117 -65 43 0 57 4 79 26 55 55 24 122 -71 156 -57 20 -76 43
-60 70 21 32 88 27 99 -8 8 -25 55 -28 55 -4 0 29 -21 56 -55 70 -43 18 -72
17 -112 -3z"/>
<path d="M3010 2075 c0 -147 10 -177 65 -195 45 -15 86 -6 116 26 22 24 24 35
27 160 l4 134 -31 0 -31 0 0 -128 c0 -134 -5 -152 -45 -152 -40 0 -45 18 -45
152 l0 128 -30 0 -30 0 0 -125z"/>
<path d="M3280 2034 l0 -167 87 5 c79 4 88 7 110 32 30 35 31 88 1 115 -19 19
-20 22 -6 38 23 28 28 50 17 82 -14 41 -62 61 -143 61 l-66 0 0 -166z m154 84
c14 -39 -6 -58 -60 -58 l-44 0 0 38 c0 49 5 54 54 50 34 -2 42 -8 50 -30z m-3
-119 c14 -14 18 -27 13 -42 -9 -31 -23 -38 -69 -35 -37 3 -40 5 -43 36 -2 19
-1 40 2 48 8 22 72 18 97 -7z"/>
<path d="M3092 1831 c-8 -5 -12 -22 -10 -42 2 -28 7 -34 27 -34 13 0 26 5 28
11 2 6 -7 10 -19 10 -32 -1 -32 38 0 42 12 2 20 8 17 13 -8 11 -26 11 -43 0z"/>
<path d="M3167 1813 c-11 -10 -8 -51 3 -58 17 -10 50 5 50 24 0 30 -35 53 -53
34z m33 -37 c-14 -14 -24 0 -16 21 6 15 8 15 16 3 6 -9 5 -19 0 -24z"/>
<path d="M3241 1782 c1 -36 2 -36 8 -7 8 37 25 36 30 -2 l4 -28 6 30 c7 33 26
37 26 5 0 -10 4 -23 9 -27 4 -4 6 8 4 27 -3 32 -5 34 -45 37 l-43 3 1 -38z"/>
<path d="M3380 1786 c0 -20 5 -36 10 -36 6 0 10 14 10 31 0 20 4 29 13 26 7
-2 11 -15 9 -30 -2 -15 1 -27 8 -27 7 0 10 13 8 33 -2 27 -8 33 -30 35 -26 3
-28 1 -28 -32z"/>
<path d="M3467 1813 c-3 -5 -7 -26 -8 -48 -4 -37 -2 -40 21 -40 16 0 26 6 28
18 2 10 -3 17 -13 17 -15 0 -15 2 -1 15 8 9 13 22 10 30 -6 16 -30 20 -37 8z
m23 -18 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10
-15z m5 -55 c-3 -5 -10 -10 -16 -10 -5 0 -9 5 -9 10 0 6 7 10 16 10 8 0 12 -4
9 -10z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
